<template>
  <md-card>
    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-text-field
                    label="Alias"
                    v-model="form.alias"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.alias") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <v-text-field
                    label="Parameter Name"
                    v-model="form.parameterName"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.parameterName") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="variables"
                    label="Variable"
                    :rules="[rules.required]"
                    v-model="form.variableId"
                  >
                    <template slot="item" slot-scope="data">
                      <template>
                        <span>
                          <v-icon color="primary" class="mr-2">{{
                            data.item.icons[0]
                          }}</v-icon>
                          {{ data.item.name }}</span
                        >
                      </template>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <template>
                        <span>
                          <v-icon color="primary" class="mr-2">{{
                            data.item.icons[0]
                          }}</v-icon>
                          {{ data.item.name }}</span
                        >
                      </template>
                    </template>
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.variable") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col 12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="variableUnitOfMeasurement"
                    label="Unit Of Measurement"
                    :rules="[rules.required]"
                    v-model="form.unitOfMeasurementId"
                  >
                    <template slot="item" slot-scope="data">
                      <template>
                        <span>
                          {{ data.item.name }} - {{ data.item.symbol }}</span
                        >
                      </template>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <template>
                        <span>
                          {{ data.item.name }} - {{ data.item.symbol }}</span
                        >
                      </template>
                    </template></v-select
                  >
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.unitOfMeasurement") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <!-- <md-button @click="handleSubmit('createAnother')" class="md-raised"
              >Save and create another</md-button
            > -->
              <md-button
                @click="handleSubmit('goBack')"
                class="md-raised"
                :class="[`md-${getTheme}`]"
                >Save and go back</md-button
              >
            </div>
          </div>
        </v-form>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "@/mixins/form";
import { mapGetters } from "vuex";

export default {
  name: "slot-form",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      form: {
        alias: "",
        unitOfMeasurementId: 0,
        parameterName: "",
        variableId: 0,
      },
      instantDisplayModes: [],
      historicalDisplayModes: [],
      variables: [],
      unitsOfMeasurement: [],
      editMode: false,
    };
  },
  watch: {
    "form.variableId"() {
      if (this.unitsOfMeasurement) {
        let unitOfMeasurement = this.unitsOfMeasurement.find(
          (unitOfMeasurement) =>
            unitOfMeasurement.variable_id == this.form.variableId
        );
        this.form.unitOfMeasurementId = unitOfMeasurement.id;
      }
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
    variableUnitOfMeasurement() {
      return this.unitsOfMeasurement.filter(
        (unitOfMeasurement) =>
          unitOfMeasurement.variable_id == this.form.variableId
      );
    },
  },
  mounted: function () {
    if (this.$route.params.slot) {
      this.editMode = true;
      this.getItem();
    }
    this.getDisplayModes();
    this.getVariables();
    this.getUnitsOfMeasurement();
  },
  methods: {
    getDisplayModes() {
      this.$store
        .dispatch("slot/getDisplayModes")
        .then((data) => {
          data.forEach((displayMode) => {
            if (displayMode.isHistorical) {
              this.historicalDisplayModes.push(displayMode);
            } else {
              this.instantDisplayModes.push(displayMode);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVariables() {
      this.$store
        .dispatch("slot/getVariables")
        .then((data) => {
          this.variables = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUnitsOfMeasurement() {
      this.$store
        .dispatch("slot/getUnitsOfMeasurement")
        .then((data) => {
          this.unitsOfMeasurement = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit(stay) {
      if (this.$refs.form.validate()) {
        if (this.editMode) {
          this.editItem(stay);
        } else {
          this.createItem(stay);
        }
      }
    },
    getItem() {
      this.$store
        .dispatch("slot/getSlot", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          let item = data;
          this.form.alias = item.alias;
          this.form.unitOfMeasurementId = item.unitOfMeasurementId;
          this.form.parameterName = item.parameterName;
          this.form.variableId = item.variableId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createItem(stay) {
      this.$store
        .dispatch("slot/createSlot", {
          device: this.$route.params.device,
          alias: this.form.alias,
          unit_of_measurement_id: this.form.unitOfMeasurementId,
          historical_display_mode_id: this.form.historicalDisplayModeId,
          display_mode_id: this.form.displayModeId,
          parameter_name: this.form.parameterName,
          show_historical: this.form.showHistorical,
          title: this.form.title,
          color: this.form.color,
        })
        .then((data) => {
          this.$notify({
            message: "The slot was created",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          switch (stay) {
            case "createAnother":
              this.cleanForm();
              break;
            case "stay":
              this.editMode = true;
              this.$router.push(
                "/devices/edit/" + data.device + "/slots/edit/" + data.slot
              );
              break;
            case "goBack":
              this.goBackWithDelay();
              break;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            message: "Something went wrong",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    editItem(stay) {
      this.$store
        .dispatch("slot/editSlot", {
          device: this.$route.params.device,
          id: this.$route.params.slot,
          alias: this.form.alias,
          unitOfMeasurementId: this.form.unitOfMeasurementId,
          parameterName: this.form.parameterName,
        })
        .then(() => {
          this.$notify({
            message: "The slot was updated",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          if (stay === "goBack") {
            this.goBackWithDelay();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            message: "Something went wrong",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    goBackWithDelay() {
      setTimeout(() => {
        this.$router.push({
          name: "deviceSlots",
          params: { device: this.$route.params.device },
        });
      }, 1000);
    },
  },
};
</script>